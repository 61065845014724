import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logo from './Logo';

// style constant
const useStyles = makeStyles((theme) => ({
	progressBar: {
		position: 'fixed',
		top: 200,
		left: 0,
		zIndex: 0,
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
	logo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'fixed',
		top: 160,
		left: 0,
		zIndex: 0,
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

// -----------------------|| Loader ||-----------------------//

const Loader = () => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.logo}>
				<Logo />
			</div>,

			<div className={classes.progressBar}>
				<LinearProgress color="primary" />


			</div>
		</>
	);
};

export default Loader;
