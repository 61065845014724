import 'dotenv/config';

const config = {
	// basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
	basename: '',
	defaultPath: '/dashboard/default',
	fontFamily: "'Roboto', sans-serif",
	borderRadius: 12,
	// API_SERVER: 'https://backend.dyagnosys.com/api/',
	REACT_APP_GOOGLE_CLIENT_ID: '1094842325319-qabtq02sivg5c4aqj21uvmjsj389gm22.apps.googleusercontent.com',
	url_google_entrar: '/api/users/v1/auth/google/entrar/',
	url_google_cadastrar: '/api/users/v1/auth/google/cadastrar',
	url_api_login: '/api/users/login/',
	// REACT_APP_BACKEND: 'http://localhost:5000',
	// REACT_APP_BACKEND: 'https://backend.dyagnosys.com',
};

export default config;
