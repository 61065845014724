import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	// InputAdornment,
	List,
	ListItemIcon,
	ListItemText,
	// OutlinedInput,
	Paper,
	Popper,
	Switch,
	Typography,
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import { IconLogout, IconSearch, IconSettings, IconChevronDown } from '@tabler/icons';

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import { LOGOUT } from '../../../../store/actions';
// import UpgradePlanCard from './UpgradePlanCard';

// assets
// Barra de Noficação
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// style const
const useStyles = makeStyles((theme) => ({
	navContainer: {
		width: '100%',
		maxWidth: '350px',
		minWidth: '300px',
		backgroundColor: theme.palette.background.paper,
		borderRadius: '10px',
		[theme.breakpoints.down('sm')]: {
			minWidth: '100%',
		},
	},
	headerAvatar: {
		cursor: 'pointer',
		...theme.typography.mediumAvatar,
		margin: '8px 0 8px 8px !important',
	},
	profileChip: {
		height: '48px',
		alignItems: 'center',
		borderRadius: '27px',
		transition: 'all .2s ease-in-out',
		borderColor: theme.palette.primary.light,
		backgroundColor: theme.palette.primary.light,
		'&[aria-controls="menu-list-grow"], &:hover': {
			borderColor: theme.palette.primary.main,
			background: `${theme.palette.primary.main}!important`,
			color: theme.palette.primary.light,
			'& svg': {
				stroke: theme.palette.primary.light,
			},
		},
	},
	profileLabel: {
		lineHeight: 0,
		padding: '12px',
	},
	listItem: {
		marginTop: '5px',
	},
	cardContent: {
		padding: '16px !important',
	},
	card: {
		backgroundColor: theme.palette.primary.light,
		marginBottom: '16px',
		marginTop: '16px',
	},
	searchControl: {
		width: '100%',
		paddingRight: '8px',
		paddingLeft: '16px',
		marginBottom: '16px',
		marginTop: '16px',
	},
	startAdornment: {
		fontSize: '1rem',
		color: theme.palette.grey[500],
	},
	flex: {
		display: 'flex',
	},
	name: {
		marginLeft: '2px',
		fontWeight: 400,
	},
	ScrollHeight: {
		height: '100%',
		maxHeight: 'calc(100vh - 250px)',
		overflowX: 'hidden',
	},
	badgeWarning: {
		backgroundColor: theme.palette.warning.dark,
		color: '#fff',
	},
}));

// -----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
	const [loading, setLoading] = React.useState('none');
	const classes = useStyles();
	const theme = useTheme();
	const customization = useSelector((state) => state.customization);
	const account = useSelector((state) => state.account);
	const dispatcher = useDispatch();
	const [sdm, setSdm] = React.useState(true);
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	//===========================|| SnackBar Feedback ||============================//
	const [alertstate, setAlertstate] = React.useState({
		openAlert: false,
		vertical: 'top',
		horizontal: 'left',
		durationAlert: 3000,
		typeAlert: 'info', 	//success | error | info | warning
		transition: Fade,
		messageAlert: 'Carregando....',
	});

	const { vertical, horizontal, openAlert, durationAlert, transition, typeAlert,
		messageAlert } = alertstate;

	const handleCloseAlert = () => {
		setAlertstate({ ...alertstate, openAlert: false });
	};



	const handleLogout = () => {
		setLoading('flex')
		setAlertstate({
			...alertstate, messageAlert: 'Saindo do sistema, aguarde...',
			durationAlert: 5000,
			typeAlert: 'warning', openAlert: true
		});

		axios
			.post(process.env.REACT_APP_BACKEND + '/api/users/logout', {}, { headers: { Authorization: `${account.token}` } })
			.then((response) => {
				dispatcher({ type: LOGOUT });
			})
			.catch((error) => {
				console.log('ERROR:: ', error);
			});
	};


	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);



	return (
		<>

			{/* NOTIFICATION */}

			<Snackbar
				open={openAlert}
				onClose={handleCloseAlert}
				autoHideDuration={durationAlert}
				anchorOrigin={{ vertical, horizontal }}
				key={vertical + horizontal}
				TransitionComponent={transition}
			>
				<Alert onClose={handleCloseAlert} severity={typeAlert}
					sx={{ width: '100%' }}>
					{messageAlert}
				</Alert>
			</Snackbar>

			<Box sx={{ display: loading }} style={{ paddingRight: '8px' }}>
				<CircularProgress color="success" size="1.8rem" />
			</Box>
			<Chip
				classes={{ label: classes.profileLabel }}
				className={classes.profileChip}
				icon={
					<Avatar
						src={account.user.picture}
						className={classes.headerAvatar}
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						color="inherit"
					/>


				}

				label={<IconChevronDown stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
				variant="outlined"
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				color="primary"
			/>

			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 14],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => (
					<Transitions in={open} {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
									<CardContent className={classes.cardContent}>
										<Grid container direction="column" spacing={0}>
											<Grid item className={classes.flex}>
												<Typography variant="h4">Olá, </Typography>
												<Typography component="span" variant="h4" className={classes.name}>
													{account.user.GivenName}
												</Typography>
											</Grid>
											<Grid item><Typography variant="subtitle2">Seja bem vindo!</Typography></Grid>
										</Grid>
										{/* <OutlinedInput
                                            className={classes.searchControl}
                                            id="input-search-profile"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Search profile options"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconSearch stroke={1.5} size="1.3rem" className={classes.startAdornment} />
                                                </InputAdornment>
                                            }
                                            aria-describedby="search-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight'
                                            }}
                                        /> */}
										<br />
										<Divider />
										<PerfectScrollbar className={classes.ScrollHeight}>
											{/* <UpgradePlanCard />
                                            <Divider /> */}
											<Card className={classes.card}>
												<CardContent>
													<Grid container spacing={3} direction="column">
														<Grid item>
															<Grid item container alignItems="center" justifyContent="space-between">
																<Grid item>
																	<Typography variant="subtitle1">Receber Newsletter</Typography>
																</Grid>
																<Grid item>
																	<Switch
																		color="primary"
																		checked={sdm}
																		onChange={(e) => setSdm(e.target.checked)}
																		name="sdm"
																		size="small"
																	/>
																</Grid>
															</Grid>
														</Grid>
														<Grid item>
															<Grid item container alignItems="center" justifyContent="space-between">
																<Grid item>
																	<Typography variant="subtitle1">Configurações</Typography>
																</Grid>

																{/* <Grid item>
																	<Switch
																		checked={notification}
																		onChange={(e) => setNotification(e.target.checked)}
																		name="sdm"
																		size="small"
																	/>
																</Grid> */}
															</Grid>
														</Grid>
													</Grid>
												</CardContent>
											</Card>
											<Divider />
											<List component="nav" className={classes.navContainer}>
												<ListItemButton
													className={classes.listItem}
													sx={{ borderRadius: `${customization.borderRadius}px` }}
													selected={selectedIndex === 4}
													onClick={handleLogout}
												>
													<ListItemIcon>
														<IconLogout stroke={1.5} size="1.3rem" />
													</ListItemIcon>
													<ListItemText primary={<Typography variant="body2">Sair</Typography>} />
												</ListItemButton>
											</List>
										</PerfectScrollbar>
									</CardContent>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</>
	);
};

export default ProfileSection;
