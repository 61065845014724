import PropTypes from 'prop-types';
import React from 'react';

// project imports
import Customization from '../Customization';

// -----------------------|| MINIMAL LAYOUT ||-----------------------//

const MinimalLayout = (props) => (
	<>
		{props.children}
		<Customization />
	</>
);

MinimalLayout.propTypes = {
	children: PropTypes.node,
};

export default MinimalLayout;
