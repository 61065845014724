import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

//AI
const faceEmotions = Loadable(lazy(() => import('../views/ai/faceEmotion/index')));
// const toneEmotions = Loadable(lazy(() => import('../views/ai/tone-emotions')));
// const deviceEmotions = Loadable(lazy(() => import('../views/ai/device-emotions')));

//Anamnese 
// const anamneseDigital = Loadable(lazy(() => import('../views/anamnese/anamnese-digital')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

// -----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
	const location = useLocation();

	return (
		<Route
			path={[
				'/dashboard/default',
				'/utils/util-typography',
				'/utils/util-color',
				'/utils/util-shadow',
				'/icons/tabler-icons',
				'/icons/material-icons',
				'/sample-page',
				'/ai/face-emotions',
				// '/ai/tone-emotions',
				// '/ai/device-emotions',
				// '/anamnese/anamnese-digital'
			]}
		>
			<MainLayout>
				<Switch location={location} key={location.pathname}>
					<AuthGuard>
						<Route path="/dashboard/default" component={DashboardDefault} />
						<Route path="/ai/face-emotions" component={faceEmotions} />
						{/* <Route path="/ai/tone-emotions" component={toneEmotions} /> */}
						{/* <Route path="/ai/device-emotions" component={deviceEmotions} /> */}
						{/* <Route path="/anamnese/anamnese-digital" component={anamneseDigital} /> */}
						<Route path="/utils/util-typography" component={UtilsTypography} />
						<Route path="/utils/util-color" component={UtilsColor} />
						<Route path="/utils/util-shadow" component={UtilsShadow} />
						<Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
						<Route path="/icons/material-icons" component={UtilsMaterialIcons} />
						<Route path="/sample-page" component={SamplePage} />
					</AuthGuard>
				</Switch>
			</MainLayout>
		</Route>
	);
};

export default MainRoutes;
