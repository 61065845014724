// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from './actions';
import fakePicture from '../assets/images/users/user-round.svg';


export const initialState = {
	token: '',
	isLoggedIn: false,
	isInitialized: false,
	user: null,
	user: {
		picture: fakePicture,
		GivenName: 'Convidado'
	},
	picture: fakePicture,
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACCOUNT_INITIALIZE: {
			const { isLoggedIn, user, token } = action.payload;
			return {
				...state,
				isLoggedIn,
				isInitialized: true,
				token,
				user,
			};
		}
		case LOGIN: {
			const { user } = action.payload;
			return {
				...state,
				isLoggedIn: true,
				user,
				picture,
			};
		}
		case LOGOUT: {
			return {
				...state,
				isLoggedIn: false,
				token: '',
				picture: fakePicture,
			};
		}
		default: {
			return { ...state, picture: fakePicture };
		}
	}
};

export default accountReducer;
