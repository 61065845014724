// assets
import {
	IconSearch, IconReportMedical, IconBluetooth,
	IconMoodEmpty, IconDashboard, IconDeviceAnalytics, IconEar,
	IconReport
} from '@tabler/icons';

// constant
const icons = {
	IconReportMedical, IconDashboard,
	IconMoodEmpty, IconSearch, IconDeviceAnalytics,
	IconEar, IconReport, IconBluetooth

};

// -----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
	id: 'dashboard',
	title: 'Menu Central',
	type: 'group',
	children: [
		{
			id: 'default',
			title: 'Indicadores de Saúde',
			type: 'item',
			url: '/dashboard/default',
			icon: icons.IconReportMedical,
			breadcrumbs: false,
		},
		{
			id: 'anamnese',
			title: 'Anamnese Digital',
			type: 'item',
			url: '/anamnese/anamnese-digital',
			icon: icons.IconReport,
			breadcrumbs: false,
		},
		{
			id: 'estado-emocional-imagem',
			title: 'Estado Emocional por imagem',
			type: 'item',
			url: '/ai/face-emotions',
			icon: icons.IconMoodEmpty,
			breadcrumbs: false,
		},
		{
			id: 'estado-emocional-voz',
			title: 'Estado Emocional por voz',
			type: 'item',
			url: '/ai/tone-emotions',
			icon: icons.IconEar,
			breadcrumbs: false,
		},
		{
			id: 'estado-emocional-equipamentos',
			title: 'Estado Emocional por equipamentos médicos',
			type: 'item',
			url: '/ai/device-emotions',
			icon: icons.IconBluetooth,
			breadcrumbs: false,
		},
		// {
		// 	id: 'exames',
		// 	title: 'Exames Laboratorias',
		// 	type: 'item',
		// 	url: '/dashboard/exames',
		// 	icon: icons.IconSearch,
		// 	breadcrumbs: false,
		// },
	],
};
