import { dashboard } from './dashboard';
import { utilities } from './utilities';
import { other } from './other';

// -----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
	// items: [dashboard, utilities, other]
	items: [dashboard],
};

export default menuItems;
